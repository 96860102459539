
import { useMsal } from "@azure/msal-react";
import {
  CredentialResponse,
  GoogleLogin, GoogleOAuthProvider
} from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactModal from 'react-modal';
import bg from '../../assets/images/bg.png';
import logo from '../../assets/images/logo.svg';
import logoMicrosoft from '../../assets/images/microsoft.svg';
import { configrationApp, customStylesModalBase } from '../../core/config';
import { ResponseRegisterMailDTO } from '../../core/dtos';
import { AdminService } from '../../core/services/AdminService';
import Footer from '../Footer';
import MailSent from '../modals/mailSsent';
import UserExists from '../modals/userExists';
import Contacts from '../parts/Contacts';

import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
let hasValidation = false;
type FormInputs = {
  email: string
}
export default function Home(props: { msal: PublicClientApplication }) {
  const { googleClientId, urlLogin } = configrationApp();
  const { instance } = useMsal();

  const [modalState, setModalState] = useState({
    open: false,
    created: false
  });
  const [error, setError] = useState('');
  const [registerState, setRegisterState] = useState({
    idActivation: '',
    idUser: 0
  } as ResponseRegisterMailDTO)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();


  !hasValidation && addListenerMSAL();
  function addListenerMSAL(): void {
    hasValidation = true;
    props.msal.addEventCallback((event: EventMessage) => {
      const listEvents = [EventType.ACQUIRE_TOKEN_SUCCESS, EventType.LOGIN_SUCCESS];
      if (listEvents.some(e => e === event.eventType) && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        registerMail({
          email: account.username
        });
      }
    });
  }
  function registerMail(data: FormInputs) {
    setError('');
    AdminService.getInstance().registerEmail(data.email).then(res => {
      setModalState({
        open: true,
        created: true
      });
      setRegisterState(res);
    }).catch(error => {
      if (error?.response?.data?.code === 'HAS_USER') {
        setModalState({
          open: true,
          created: false
        });
        return;
      }
      const messageError = error?.response?.data?.message || 'Error en el servicio';
      setError(messageError);
    })
  }

  function succesLoginGoogle(credentials: CredentialResponse) {
    const data = jwtDecode(credentials.credential as string) as any;
    console.log('succesLoginGoogle', data)
    if (data.email) {
      registerMail({
        email: data.email
      })
    }
  }
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.loginPopup();
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  }
  return (
    <GoogleOAuthProvider clientId={googleClientId} >
      <div className='min-h-screen flex '>
        <div className='min-h-screen flex flex-col justify-between items-center p-4 w-full lg:w-6/12 '>
          <div></div>
          <section className=''>
            <picture className='flex justify-center mb-2 '>
              <img src={logo} alt="Logo Acelera" />
            </picture>
            <h1 className='text-2xl font-medium text-center mb-6'>
              ¡Comencemos!
            </h1>
            <form onSubmit={handleSubmit((data) => registerMail(data))}>
              <input type="email" placeholder='Ingresa tu correo ' className='form-input'  {...register('email', { required: true })} />
              {
                errors.email && (
                  <div className='text-red-500 flex items-center my-1 text-xs'>
                    <AiOutlineInfoCircle></AiOutlineInfoCircle>
                    <span className='pl-1'>
                      Ingresa un correo electrónico válido
                    </span>
                  </div>
                )
              }
              {
                error && <div className='text-red-500 flex items-center my-1 text-xs'>
                  <AiOutlineInfoCircle></AiOutlineInfoCircle>
                  <span className='pl-1'>
                    {error}
                  </span>
                </div>
              }

              <button type="submit" className='btn btn--block mt-5'>
                Registrate - es gratis</button>

            </form>
            <div className='my-4 font-medium text-center'>
              ¿Ya tienes una cuenta?
              <a className='underline ml-1' href={urlLogin} target='_blank' rel="noreferrer">Acceso</a>
            </div>
            <div className='flex items-center my-6'>
              <hr className='w-full' />
              <div className='mx-2'>o</div>
              <hr className='w-full' />
            </div>
            <div>
              <GoogleLogin width={300}
                onSuccess={credentialResponse => {
                  succesLoginGoogle(credentialResponse)
                }}
                onError={() => {

                  console.log('Login Failed');
                }}
              />
              <button
                onClick={() => handleLogout("popup")}
                className="flex justify-center items-center border text-sm font-medium
                border-solid border-gray-300 rounded-sm w-full p-2 mt-3"
              >
                <img src={logoMicrosoft} alt="Microsoft" className="mr-2 w-[18px]" />
                Iniciar sesión con Microsoft
              </button>
            </div>
          </section>
          <Footer></Footer>
        </div>
        <img src={bg} alt="Fondo Acelera" className='max-h-screen w-full lg:w-6/12 hidden lg:block object-cover' />
        <ReactModal isOpen={modalState.open} ariaHideApp={false}
          style={
            {
              overlay: {
                ...customStylesModalBase.overlay,
              },
              content: {
                ...customStylesModalBase.content,
              }
            }
          }
        >
          {
            modalState.created ? <MailSent idActivation={registerState.idActivation} idUser={registerState.idUser} /> : <UserExists />
          }

          <div className='mt-2 lg:mt-4'>
            <Contacts inline={true} />
          </div>
        </ReactModal>
      </div>
    </GoogleOAuthProvider>

  )
}
